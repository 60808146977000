import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './styles/Login.css';
import { db } from '../Backend/Firebase';
import ReCAPTCHA from 'react-google-recaptcha';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';


const Login = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [state, setState] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [captchaToken, setCaptchaToken] = useState(null);
  const [showForm, setShowForm] = useState(false);
  

  const navigate = useNavigate();

  const handleCaptcha = (token) => {
    // Send the token to your backend for verification
    setCaptchaToken(token);
  };

  const handleSignup = async (e) => {
    // Validate empty fields
    e.preventDefault();

    if (!name || !email || !state) {
      setErrorMessage('Please fill in all fields.');
      return;
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }

    try {
      // Save user details to Firestore
      const userDocRef = await addDoc(collection(db, 'users'), {
        name,
        email,
        state,
      });

      console.log('Document written with ID: ', userDocRef.id);

      setName('');
      setEmail('');
      setState('');
      setSuccessMessage('Sign up Successful! Thank you');

      // Clear messages after 10 seconds
      setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
      }, 10000);

      // Redirect to the home page after successful signup
      navigate('/');

    }
    catch (error) {
      console.error('Error signing up: ', error.message);
      setErrorMessage('Error signing up. Please try again.'); 
      // Handle error
    }
  };

  const toggleForm = () => {
    setShowForm(!showForm); // showForm state
  };

  return (
    <Container fluid className="main-container">
      <picture className='background-image'>
        <source media="(max-width: 650px)" srcSet="/assets/mobile-bg.jpg" alt = 'background'/>
        <img src="/assets/desktop-bg.jpg" alt='background'/>
      </picture>
      <div id='logo'>
              <img src="/assets/Blue BG Logo.png" alt='plustou Logo'/>
      </div>
      <Row>
      <Col md={7} className="content-container">
      <div className={`content-wrapper ${showForm ? 'hide-content' : 'show-content'}`}>
            <div className="intro-text1-layout">
              <p id='intro-text1'>Bringing introverts together to share common interests</p>
            </div>
            <div className="intro-text2-layout">
              <p id='intro-text2'>Create your meaningful moments today!</p>
            </div>
            <div className={`apply_btn ${showForm ? 'mobile' : ''}`}>
              <Button variant="primary" className="button" onClick={toggleForm}>
                Apply to Join!
              </Button>
            </div>
            <div className="intro-text3-layout">
              <p id='intro-text3'>We make it fun for introverts to get out the house and enjoy intimate connections. powered by AI and rewarded through blockchain</p>
            </div>
          </div>
        </Col>
        <Col md={6} className={`form-container col-md-8 offset-md-2 ${showForm ? 'd-block' : 'd-none'}`}>
          <div className='signup-text'>
            <h1>Sign Up Now</h1>
          </div>
          <Form onSubmit={handleSignup} className='form'>
            <div className="form-field">
              <img src="/assets/name.svg" alt="name icon" className="icon" />
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-field">
              <img src="/assets/email.svg" alt="email icon" className="icon" />
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-field">
              <img src="/assets/location.svg" alt="location icon" className="icon" />
              <select
                value={state}
                placeholder="Location"
                onChange={(e) => setState(e.target.value)}
              >
                <option value="">Location</option>
                <option value="DC">District of Columbia</option>  
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
            <div className="form-field">
              <ReCAPTCHA
              //6LdU1KwpAAAAAHXZKq-p4425vxn56UEgo5OA3ogr
              //dev site-key = 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
                sitekey="6LdU1KwpAAAAAHXZKq-p4425vxn56UEgo5OA3ogr"
                onChange={handleCaptcha}
              />
            </div>
            <div>
              <Button type="submit" className='signup_btn'>Sign up!</Button>
            </div>
          </Form>
          <Col/>
          {successMessage && (
            <p className="success-message">{successMessage}</p>
          )}
          {errorMessage && (
            <p className="error-message">{errorMessage}</p>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
